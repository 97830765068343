<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <ul>
        <li v-for="(item, index) in urlList" :key="index">
          <router-link :to="item.path" class="cWhite">{{
            item.name
          }}</router-link>
        </li>
      </ul>
      <div>
        <div>并发测试</div>
        <div>
          <div>eventId</div>
          <input v-model="eventId" />
        </div>
        <div>----------</div>
        <div>输入或添加code</div>
        <div v-for="(item, index) in codeList" :key="index">
          <input v-model="codeList[index]" />
        </div>
        <div>----------</div>
        <div><button type="button" @click="addCode">添加</button></div>
        <div><button type="button" @click="apiTest">启动测试</button></div>
        <table class="apitest-table">
          <thead>
            <tr>
              <th>userId</th>
              <th>roomId</th>
              <th>进入等待室时间</th>
              <th>进入握手时间</th>
              <th>结束时间</th>
              <th>排序</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in apiTestDataList" :key="index">
              <td>{{ item.data.userId }}</td>
              <td>{{ item.data.roomId }}</td>
              <td>{{ item.data.startWaitTime }}</td>
              <td>{{ item.data.startTime }}</td>
              <td>{{ item.data.endTime }}</td>
              <td>{{ item.data.index }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <div>视频测试</div>
        <div>
          <div>推送:</div>
          <div id="testPagePostVideo"></div>
        </div>
        <div>
          <div>拉取:</div>
          <div id="testPagePullVideo"></div>
        </div>
        <button type="button" @click="startCameraTesting" v-if="streamTestBtn">
          test!
        </button>
        <div>
          <div v-for="(log, index) in logList" :key="index">
            {{ log }}
          </div>
        </div>
      </div>
      <div class="tc cWhite" @click="testA">v0.18</div>
      <alert
        v-model:isOpen="testAlert"
        text="必ずカメラとマイクのアクセスを許可してください"
        ok-text="次へ"
        @ok="testAlert = false"
        :has-close="false"
      ></alert>
      <alert
        v-model:isOpen="autoVoiceErrorAlertIsOpen"
        text="許可してください"
        ok-text="次へ"
        @ok="playRemoteStream"
        :has-close="false"
      ></alert>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage } from '@ionic/vue'
import { defineComponent } from 'vue'
import { authApi } from '../api'
import axios from 'axios'
import globalConfig from '../config/config'
import Alert from '../components/Alert.vue'
import TRTC from 'trtc-js-sdk'

export default defineComponent({
  name: 'Home',
  data() {
    return {
      testAlert: false,
      apiTestDataList: [],
      eventId: '',
      maxVal: 0,
      codeList: [''],
      logList: [],
      localStream: null,
      remoteStream: null,
      streamTestBtn: true,
      autoVoiceErrorAlertIsOpen: false,
      localStreamIsInited: false,
      remoteStreamIsInited: false,
      urlList: [
        {
          name: '设备检测',
          path: '/devicetest?userid=1'
        },
        {
          name: '入口',
          path: '/entrance?authcode=205378a8-38c0-40bb-8093-3a7948e500cd'
        },
        {
          name: '直播前&直播中',
          path: '/user/meeting/1/streaming'
        },
        {
          name: '直播后',
          path: '/user/meeting/1/streamingend'
        },
        {
          name: '聊天室',
          path: '/user/meeting/1/chatroom/'
        },
        {
          name: 'Ban',
          path: '/user/meeting/1/streamingend?type=1'
        }
      ]
    }
  },
  components: {
    IonContent,
    IonPage,
    Alert
  },
  mounted() {
    // setTimeout(() => {
    //   this.testAlert = true
    // }, 1000)
    // this.testA()
    // this.testB()
  },
  methods: {
    async apiTest() {
      if (this.eventId === '') {
        alert('请输入活动ID')
        return false
      }
      const codeList = this.codeList.filter(code => {
        return code !== ''
      })
      const jwtList = []
      for (let i = 0; i < codeList.length; i++) {
        const item = codeList[i]
        const params = {
          provider: 'authcode',
          token: item,
          userid: 'authcode'
        }
        await authApi
          .login(params)
          .then(async loginRes => {
            if (loginRes.data.jwt) {
              jwtList.push(loginRes.data.jwt)
            }
          })
          .catch(() => {
            alert('code已使用或者有误:' + item)
          })
      }

      const promiseList = []
      jwtList.forEach((item, index) => {
        promiseList[index] = new Promise(resolve => {
          axios.defaults.headers.common['Authorization'] = `Bearer ${item}`
          axios
            .post(
              `${globalConfig.userApiBaseUrl}/events/${this.eventId}/chatroom`
            )
            .then(item => {
              resolve(item)
            })
        })
      })
      Promise.all(promiseList).then(values => {
        console.log(values)
        this.apiTestDataList = values.sort((a, b) => {
          return a.data.index - b.data.index
        })
      })
    },

    addCode() {
      this.codeList.push('')
    },
    testB() {
      const that = this
      navigator.mediaDevices
        .getUserMedia({ audio: true, video: true })
        .then(function(stream) {
          const audioContext = new AudioContext()
          const analyser = audioContext.createAnalyser()
          const microphone = audioContext.createMediaStreamSource(stream)
          const javascriptNode = audioContext.createScriptProcessor(2048, 1, 1)

          analyser.smoothingTimeConstant = 0.8
          analyser.fftSize = 1024

          microphone.connect(analyser)
          analyser.connect(javascriptNode)
          javascriptNode.connect(audioContext.destination)
          javascriptNode.onaudioprocess = function() {
            var array = new Uint8Array(analyser.frequencyBinCount)
            analyser.getByteFrequencyData(array)
            var values = 0

            var length = array.length
            for (var i = 0; i < length; i++) {
              values += array[i]
            }

            var average = values / length

            // console.log(Math.round(average))
            that.maxVal = Math.round(average)
            // colorPids(average);
          }
        })
        .catch(function() {
          /* handle the error */
        })
    },
    testA() {
      navigator.getUserMedia(
        { video: true, audio: true },
        function onSuccess() {
          console.log('已点击允许,开启成功')
        },
        function onError(error) {
          console.log('错误：', error)
        }
      )
    },
    // 推拉流测试
    playRemoteStream() {
      this.autoVoiceErrorAlertIsOpen = false
      this.$nextTick(() => {
        if (this.remoteStream) {
          this.remoteStream.resume()
        }
      })
    },
    async startCameraTesting() {
      this.streamTestBtn = false
      await this.createLocalStream(
        {
          audio: true,
          video: true,
          facingMode: 'user'
        },
        'testPagePostVideo'
      )
      this.startNetworkTesting()
    },
    async startNetworkTesting() {
      this.logList.push(`startNetworkTesting`)
      // 获取信息
      let userInfo = {}
      let liverInfo = {}
      let appInfo = {}
      this.logList.push(`get test info`)
      const testInfo = await authApi.devicetest().catch(err => {
        console.error(err)
        this.networdCheckError = true
        return false
      })
      this.logList.push(`get test info ok`)
      console.log(testInfo)
      appInfo = {
        sdkAppId: testInfo.data.appId,
        roomId: testInfo.data.roomNum
      }
      userInfo = {
        userId: testInfo.data.downLinkUser.userId,
        userSig: testInfo.data.downLinkUser.userSig
      }
      liverInfo = {
        userId: testInfo.data.upLinkUser.userId,
        userSig: testInfo.data.upLinkUser.userSig
      }
      let uplinkClient = null // 用于检测上行网络质量
      let downlinkClient = null // 用于检测下行网络质量

      // 1. 检测上行网络质量
      const testUplinkNetworkQuality = async () => {
        uplinkClient = TRTC.createClient({
          sdkAppId: appInfo.sdkAppId, // 填写 sdkAppId
          userId: userInfo.userId,
          userSig: userInfo.userSig, // uplink_test 的 userSig
          mode: 'rtc',
          autoSubscribe: false,
          enableAutoPlayDialog: false
        })

        // localStream = TRTC.createStream({ audio: true, video: true })
        // // 根据实际业务场景设置 video profile
        // localStream.setVideoProfile('480p')
        // await localStream.initialize()

        uplinkClient.on('network-quality', event => {
          console.log(event)
          // const { uplinkNetworkQuality, uplinkRTT } = event

          // this.logList.push(`uplinkNetworkQuality ${uplinkNetworkQuality}`)
          // this.logList.push(`uplinkRTT ${uplinkRTT}`)
        })

        // 加入用于测试的房间，房间号需要随机，避免冲突
        this.logList.push('localStream try join')
        await uplinkClient
          .join({ roomId: appInfo.roomId })
          .then(() => {
            this.logList.push('localStream join ok')
          })
          .catch(error => {
            this.logList.push(
              'E0007 uplinkClient join error:' + JSON.stringify(error)
            )
          })
        this.logList.push('localStream try publish')
        await uplinkClient
          .publish(this.localStream)
          .then(() => {
            this.logList.push('localStream published')
          })
          .catch(err => {
            console.error(err)
            this.logList.push('publishStream error E0006')
            this.logList.push(JSON.stringify(err, null))
            return false
          })
      }

      // 2. 检测下行网络质量
      const testDownlinkNetworkQuality = async () => {
        downlinkClient = TRTC.createClient({
          sdkAppId: appInfo.sdkAppId, // 填写 sdkAppId
          userId: liverInfo.userId,
          userSig: liverInfo.userSig, // userSig
          mode: 'rtc',
          enableAutoPlayDialog: false

          // autoSubscribe: false
        })
        downlinkClient.on('stream-subscribed', event => {
          let remoteStream = event.stream
          this.remoteStream = null
          this.remoteStream = remoteStream
          console.log('远端流订阅成功：' + remoteStream.getId())
          this.logList.push(
            'stream-subscribed ok userId is' +
              remoteStream.getUserId() +
              ',ID is ' +
              remoteStream.getId()
          )
          remoteStream.play('testPagePullVideo')
          remoteStream.on('player-state-changed', event => {
            console.log(
              `${event.type} player is ${event.state} because of ${event.reason}`
            )
            const state = event.state
            const reason = event.reason
            switch (state) {
              case 'PLAYING':
                this.remoteStreamIsInited = true
                this.logList.push(`remoteStream PLAYING`)
                break
              case 'PAUSED':
                switch (reason) {
                  case 'pause':
                    if (this.remoteStreamIsInited) {
                      this.logList.push(
                        `remoteStream ${event.type} player is ${event.state} because of ${event.reason} PAUSED E0004`
                      )
                    } else {
                      this.logList.push(
                        `remoteStream ${event.type} player is ${event.state} because of ${event.reason} PAUSED`
                      )
                    }

                    break
                  default:
                    break
                }
                break
              case 'STOPPED':
                this.logList.push('remoteStream STOPPED！！')
                // this.logList.push('try remoteStream replay！！')
                // this.playErrorAlertOpen = true
                break
              default:
                break
            }
          })
          remoteStream.on('error', error => {
            const errorCode = error.getCode()
            this.logList.push('remoteStream error code is ' + errorCode)
            if (errorCode === 0x4043) {
              this.$nextTick(() => {
                this.autoVoiceErrorAlertIsOpen = true
              })
              // alert('no voice')
              // PLAY_NOT_ALLOWED,引导用户手势操作并调用 stream.resume 恢复音视频播放
              // stream.resume()
            } else {
              this.logList.push('remoteStream play is Error E0005')
            }
          })
        })
        downlinkClient.on('stream-added', async event => {
          await downlinkClient
            .subscribe(event.stream, {
              audio: true,
              video: true
            })
            .catch(() => {
              this.logList.push(`subscribe error E0003`)
            })
          // 订阅成功后开始监听网络质量事件
          downlinkClient.on('network-quality', event => {
            console.log(event)
            // const { downlinkNetworkQuality, downlinkRTT } = event

            // this.logList.push(
            //   `downlinkNetworkQuality ${downlinkNetworkQuality}`
            // )
            // this.logList.push(`downlinkRTT ${downlinkRTT}`)
          })
        })
        // 加入用于测试的房间，房间号需要随机，避免冲突
        await downlinkClient.join({ roomId: appInfo.roomId }).catch(error => {
          this.logList.push(
            'E0007 downlinkClient join error:' + JSON.stringify(error)
          )
        })
      }

      // 3. 开始检测
      testUplinkNetworkQuality()
      testDownlinkNetworkQuality()
    },
    async createLocalStream(constraints, container) {
      this.localStream = TRTC.createStream(constraints)
      console.log('..............track:', this.localStream.getVideoTrack())
      navigator.mediaDevices
        .getUserMedia({ video: true, audio: true })
        .then(async () => {
          console.log('..........getUserMedia:', 'ok')
        })
        .catch(error => {
          console.log('..........getUserMedia error:', error)
        })
      try {
        await this.localStream.initialize()
      } catch (error) {
        console.log(error)
      }
      this.logList.push('localStream try play')
      container && this.localStream.play(container)
      this.logList.push('localStream play')
      this.localStream.on('player-state-changed', event => {
        console.log(
          `${event.type} player is ${event.state} because of ${event.reason}`
        )
        // 若 LocalStream 触发该事件，表明音频/视频采集暂停，通常是设备异常引起，如设备被其他应用抢占，此时需引导用户重新采集。
        const state = event.state
        const reason = event.reason
        switch (state) {
          case 'PLAYING':
            this.logList.push(`localStream PLAYING`)
            this.localStreamIsInited = true
            break
          case 'PAUSED':
            switch (reason) {
              case 'mute':
                this.logList.push(
                  `localStream ${event.type} player is ${event.state} because of ${event.reason} E0002`
                )
                break
              case 'pause':
                if (this.localStreamIsInited) {
                  this.logList.push(
                    `localStream ${event.type} player is ${event.state} because of ${event.reason} E0001`
                  )
                } else {
                  this.logList.push(
                    `localStream ${event.type} player is ${event.state} because of ${event.reason}`
                  )
                }

                break
              default:
                break
            }
            break
          case 'STOPPED':
            this.logList.push('localStream STOPPED！！')
            break
          default:
            break
        }
      })
    }
  }
})
</script>

<style scoped>
.apitest-table {
  width: 100%;
}
.apitest-table th,
.apitest-table td {
  padding: 0 5px;
}
</style>
